import axios from './interceptor-axios';
import Config from '~/config';

function upgradeExtraLicenseWithCredit(params) {
  const request = {
    method: 'POST',
    url: `${Config.apiUrl.apiData}/extra_license/register`,
    data: {
      currency: params.currency,
      packId: params.packId,
      lang: params.lang,
      charge: params.charge,
      customerId: params.customerId,
      needConfirm3DS: params.needConfirm3DS,
    },
  };

  console.log('--- check request of extra request ---');
  console.log(request);
  console.log('--- check request of extra request ---');
  return axios(request);
}

function saveExtraLicense(params) {
  const request = {
    method: 'POST',
    url: `${Config.apiUrl.apiData}/extra_license/data`,
    data: {
      service_type: params.service_type,
      data_id: params.data_id,
      fullname: params.fullname,
      reason: params.reason,
      remark: params.remark,
      destinationUrl: params.destinationUrl,
    },
  };
  return axios(request);
}

function getExtraLicensePlan(params) {
  const request = {
    method: 'GET',
    url: `${Config.apiUrl.apiData}/extra_license/packs`,
    params: {
      service_type: Config.serviceType,
    },
  };
  return axios(request);
}

function cancelExtraLicensePlan(params) {
  const request = {};
  return axios(request);
}

export {
  getExtraLicensePlan,
  upgradeExtraLicenseWithCredit,
  cancelExtraLicensePlan,
  saveExtraLicense,
};
