import {
  OPEN_MODAL,
  CLOSE_MODAL,
  MODAL_SUBMIT,
  LOAD_PRICE_EL_PLANS,
  SELECTED_PRICE_EL_PLAN,
  SHOW_LOADING_FULL_SCREEN,
  HIDE_LOADING_FULL_SCREEN,
  CREATE_EL_BILLING_AGREEMENT,
  UPGRADE_EL_PLAN_WITH_CREDIT,
  SAVE_LICENSE,
  BUY_SINGLE_LICENSE,
  HIDE_LOADING_EXTRA_LICENSE,
} from './constants';

// This area is for logging modal

export function saveLicense(params) {
  return {
    type: SAVE_LICENSE,
    params,
  };
}

export function openModal(index) {
  return {
    type: OPEN_MODAL,
    index,
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function modalSubmit() {
  return {
    type: MODAL_SUBMIT,
  };
}

export function loadELPlans() {
  return {
    type: LOAD_PRICE_EL_PLANS,
  };
}

export function selectedELPlan(plan) {
  return {
    type: SELECTED_PRICE_EL_PLAN,
    plan,
  };
}

export function showLoadingFullScreen() {
  return {
    type: SHOW_LOADING_FULL_SCREEN,
  };
}

export function hideLoadingFullScreen() {
  return {
    type: HIDE_LOADING_FULL_SCREEN,
  };
}

export function createBillingELAgreement(params) {
  return {
    type: CREATE_EL_BILLING_AGREEMENT,
    ...params,
  };
}

export function upgradeExtraLicensePlan(params) {
  return {
    type: UPGRADE_EL_PLAN_WITH_CREDIT,
    ...params,
  };
}

export function buySingleLicense(payload) {
  return {
    type: BUY_SINGLE_LICENSE,
    payload,
  };
}

export function hideLoadingExtraLicense() {
  return {
    type: HIDE_LOADING_EXTRA_LICENSE,
  };
}
