import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  closeModal,
  hideLoadingFullScreen,
  selectedSinglePremium,
  showLoadingFullScreenCard,
} from './actions';
import {
  CREATE_BILLING_AGREEMENT,
  CREATE_PAYMENT_INTENT_SINGLE_PREMIUM,
  CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_FAILED,
  CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_SUCCESS,
  HIDE_LOADING_FULL_SCREEN,
  INIT_COUNTDOWN,
  LOAD_PRICE_PREMIUM,
  LOAD_PRICE_PREMIUM_ERROR,
  LOAD_PRICE_PREMIUM_SUCCESS,
  LOAD_SINGLE_PRICE_PREMIUM,
  LOAD_SINGLE_PRICE_PREMIUM_ERROR,
  LOAD_SINGLE_PRICE_PREMIUM_SUCCESS,
  REDIRECT_LOGIN,
  SHOW_LOADING_FULL_SCREEN,
  UPDATE_COUNTDOWN,
  UPDATE_COUNTDOWN_SUCCESS,
  UPGRADE_PREMIUM_WITH_CREDIT,
  INIT_COUNTDOWN_SUCCESS,
} from './constants';
import { makeSelectCoupon } from './selectors';
import { updateProfile } from '../AuthProvider/saga';
import Config from '~/config';
import Cookies from '~/services/Cookies';
import { showError } from '~/containers/CustomErrorMessage/actions';
import * as premiumService from '~/services/Premium';
import * as paymentService from '~/services/payment';
import { isError } from '~/utils/errorHandler';

import { formatFloatNumber } from '~/utils/formatter';
import I18nRouter from '~/components/I18nRouter';

function getDateTrial(prices) {
  const [trial] = prices.trial || [];
  return !!trial && trial.end_date;
}

function buildPrice(data) {
  const prices = {} || [];
  const [trial] = data.trial;

  if (trial && trial.end_date) {
    data.premium_prices.map((premium) => {
      const monthlyConversion = 0;
      const title = 'register_premium_price_title1';
      const giftPoints = 1;
      const year = '';
      let yearEn = '';

      if (premium.plan === 'MONTHLY') {
        yearEn = 'mo';
        prices[premium.plan] = {
          id: premium.id || null,
          title,
          giftPoints,
          withTax: formatFloatNumber(premium.with_tax),
          withoutTax: formatFloatNumber(premium.without_tax),
          plan: premium.plan,
          monthlyConversion,
          consumptionTax: premium.consumption_tax || null,
          year,
          yearEn,
          discount: premium.discount || null,
          end_date: premium.end_date,
          start_date: premium.start_date,
        };
      }
    });
  } else {
    data.premium_prices.map((premium) => {
      // let monthlyConversion = 0;
      // let title = 'register_premium_price_title1';
      // let giftPoints = 1;
      // let year = '';
      // let yearEn = '';

      const pricesByCurrency = premium.by_country.map((price) => ({
        price: price.price,
        monthlyConversion: premium.plan === 'ONE_YEAR' ? price.price / 12 : 0,
        currency: price.unit,
        lang: price.lang,
        currencyIcon: Config.getCurrencyIcon(price.unit),
        planId: price.plan_id,
      }));

      if (premium.plan === 'ONE_YEAR') {
        const monthlyConversion = formatFloatNumber(premium.with_tax / 12);
        const title = 'register_premium_price_title2';
        const giftPoints = 5;
        const year = 'register_premium_price_year1';
        const yearEn = 'year';

        prices[premium.plan] = {
          id: premium.id || null,
          title,
          giftPoints,
          withTax: formatFloatNumber(premium.with_tax),
          withoutTax: formatFloatNumber(premium.without_tax),
          pricesByCurrency,
          plan: premium.plan,
          monthlyConversion,
          consumptionTax: premium.consumption_tax || null,
          year,
          yearEn,
          discount: premium.discount || null,
          end_date: premium.end_date,
          start_date: premium.start_date,
        };
      }

      if (premium.plan === 'MONTHLY') {
        const monthlyConversion = 0;
        const title = 'register_premium_price_title1';
        const giftPoints = 1;
        const year = '';
        const yearEn = 'month';

        prices[premium.plan] = {
          id: premium.id || null,
          title,
          giftPoints,
          withTax: formatFloatNumber(premium.with_tax),
          withoutTax: formatFloatNumber(premium.without_tax),
          pricesByCurrency,
          plan: premium.plan,
          monthlyConversion,
          consumptionTax: premium.consumption_tax || null,
          year,
          yearEn,
          discount: premium.discount || null,
          end_date: premium.end_date,
          start_date: premium.start_date,
        };
      }
    });
  }

  return prices;
}

function buildSinglePricePremiums(singlePremiumPrices) {
  let originalPrices = singlePremiumPrices;
  let discountedPrices = [];
  if (singlePremiumPrices.originalPrices) {
    originalPrices = singlePremiumPrices.originalPrices;
    discountedPrices = singlePremiumPrices.discountedPrices;
  }
  const prices = {};
  originalPrices.forEach((premium) => {
    let title;
    let monthlyConversion;
    if (premium.type === 'ONE_YEAR') {
      title = '1 year';
      monthlyConversion = premium.price_in_china / 12;
    } else if (premium.type === 'TWO_YEAR') {
      title = '2 year';
      monthlyConversion = premium.price_in_china / 24;
    } else {
      title = '3 year';
      monthlyConversion = premium.price_in_china / 36;
    }
    const giftPoints = 5;

    prices[premium.type] = {
      id: premium.id || null,
      title,
      giftPoints,
      price: premium.price_in_china,
      type: premium.type,
      monthlyConversion,
    };
  });
  discountedPrices.forEach((item) => {
    let monthlyConversion;
    if (item.type === 'ONE_YEAR') {
      monthlyConversion = item.price_in_china / 12;
    } else if (item.type === 'TWO_YEAR') {
      monthlyConversion = item.price_in_china / 24;
    } else {
      monthlyConversion = item.price_in_china / 36;
    }
    prices[item.type].discounted = {
      id: item.id,
      price: item.price_in_china,
      type: item.type,
      monthlyConversion,
    };
  });
  return prices;
}

function findTrial(prices) {
  const [trial] = prices.trial;
  return !!(trial && trial.end_date);
}

function findSaleOff(prices) {
  return !!prices.end_date;
}

function getDateSale(prices) {
  return {
    endDate: prices.end_date,
    startDate: prices.start_date,
  };
}

function countdownForTodayExisted(coupon, request) {
  // countdown not existed in cookie
  if (!Cookies.get('countdown.date', request)) return false;
  // countdown is existed in cookie, but in different day
  // countdown.date is string in format YYYY/MM/DD
  const countdownDate = Cookies.get('countdown.date', request);
  const today = moment().format('YYYY/MM/DD');
  if (countdownDate !== today) return false;
  return true;
}

function buildCountdownDiscount(coupon, request) {
  // console.log('--- buildCountdownDiscount ---');
  const totalDurationInMilliseconds = coupon.count_duration * 1000;
  const percentOff = coupon.percent_off;
  if (!countdownForTodayExisted(coupon, request)) {
    const today = moment();
    Cookies.set('countdown.startTime', today.format());
    Cookies.set('countdown.date', today.format('YYYY/MM/DD'));
    Cookies.set('countdown.percentOff', percentOff);
    Cookies.set(
      'countdown.remainingDurationInMilliseconds',
      totalDurationInMilliseconds,
    );
    console.log('--- not existed ---', {
      percentOff,
      remainingDurationInMilliseconds: totalDurationInMilliseconds,
    });
    return {
      percentOff,
      remainingDurationInMilliseconds: totalDurationInMilliseconds,
    };
  }

  const startTime = moment(Cookies.get('countdown.startTime', request));
  const elapsedDurationInMilliseconds = moment().diff(startTime);
  const remainingDurationInMilliseconds = Math.max(
    0,
    totalDurationInMilliseconds - elapsedDurationInMilliseconds,
  );
  Cookies.set(
    'countdown.remainingDurationInMilliseconds',
    remainingDurationInMilliseconds,
  );
  // console.log('--- existed ---', {
  //   percentOff,
  //   remainingDurationInMilliseconds,
  // });
  return {
    percentOff,
    remainingDurationInMilliseconds,
  };
}

function* initCountdown(action) {
  const currentDate = new Date().getDate();
  // const currentDate = 2;
  const discountDuration = {
    startDate: 7,
    endDate: 11,
  };
  let remainingDurationInMilliseconds;
  let typeCountdown;
  let isActive;
  const coupon = {
    percent_off: 50,
    count_duration: 60 * 60 * 24 * 5,
  };
  if (
    currentDate <= discountDuration.endDate &&
    currentDate >= discountDuration.startDate
  ) {
    // console.log('20% discounttttt');
    // remainingDurationInMilliseconds = buildCountdownDiscount(
    //   coupon,
    //   action.request,
    // ).remainingDurationInMilliseconds;
    remainingDurationInMilliseconds = moment(
      `2022-01-${discountDuration.endDate}`,
    )
      .endOf('day')
      .diff(moment());
    typeCountdown = 'FIRST_TIME';
  } else {
    return;
    // remainingDurationInMilliseconds = moment('2021-12-2').diff(moment());
    // typeCountdown = 'SECOND_TIME';
  }

  console.log(
    'remainingDurationInMilliseconds',
    remainingDurationInMilliseconds,
  );
  yield put({
    type: INIT_COUNTDOWN_SUCCESS,
    remainingDurationInMilliseconds,
    typeCountdown,
    isActive: remainingDurationInMilliseconds > 0,
    coupon,
  });
}

function* loadSinglePricePremium() {
  try {
    const prices = yield call(premiumService.getSinglePremiumPrice, {});
    if (isError(prices)) throw prices;
    const newSinglePricePremiums = buildSinglePricePremiums(prices);
    yield put({
      type: LOAD_SINGLE_PRICE_PREMIUM_SUCCESS,
      prices: newSinglePricePremiums,
      priceSelected: newSinglePricePremiums.ONE_YEAR,
    });
    if (newSinglePricePremiums.ONE_YEAR.discounted) {
      yield initCountdown();
    }
  } catch (error) {
    yield put({
      type: LOAD_SINGLE_PRICE_PREMIUM_ERROR,
      error: (error && error.response && error.response.data) || {},
    });
    yield put(showError({ error }));
  }
}

function* loadPricePremium(action) {
  try {
    const prices = yield call(premiumService.getPremiumPrice, {
      type: 'premium',
    });
    if (isError(prices)) throw prices;
    const oneYearPlan = prices.premium_prices.find(
      (item) => item.plan === 'ONE_YEAR',
    );
    yield put({
      type: LOAD_PRICE_PREMIUM_SUCCESS,
      prices: buildPrice(prices),
      isSale: findSaleOff(prices),
      saleDate: getDateSale(prices),
      trial: prices.trial,
      start_date: prices.start_date,
      end_date: prices.end_date,
      priceSelected: findTrial(prices) ? 'ONE_YEAR' : 'ONE_YEAR',
      isTrial: findTrial(prices),
      trialEndDate: getDateTrial(prices),
    });
  } catch (error) {
    yield put({
      type: LOAD_PRICE_PREMIUM_ERROR,
      error: (error && error.response && error.response.data) || {},
    });
    yield put(showError({ error }));
  }
}

function* createBillingAgreement(params) {
  yield put({ type: SHOW_LOADING_FULL_SCREEN, message: params.message });
  const { resolve, reject } = params;
  try {
    const { approval_url } = yield call(paymentService.createBillingAgreement, {
      plan: params.plan,
    });
    if (approval_url) {
      yield put({
        type: REDIRECT_LOGIN,
        link: approval_url,
        domainNew: true,
      });
    }
    resolve();
  } catch (e) {
    yield put({
      type: HIDE_LOADING_FULL_SCREEN,
    });
    reject(e);
  }
}

function* upgradePremiumWithCredit(params) {
  try {
    yield put(showLoadingFullScreenCard());
    yield put(closeModal());
    const response = yield call(premiumService.upgradePremiumWithCredit, {
      stripeToken: params.stripeToken,
      idempotencyToken: params.idempotencyToken,
      plan: params.plan,
      lang: params.lang,
      isDiscounted: params.isDiscounted,
      currency: params.currency,
      planId: params.planId,
    });
    if (isError(response)) throw response;
    if (response.isNeed3DS && params.stripe) {
      console.log('isNeed3DS', response);
      const res = yield params.stripe.confirmCardPayment(
        response.subscription.latest_invoice.payment_intent.client_secret,
      );
      console.log('isNeed3DSisNeed3DS', res);
      if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
        const resData = yield call(premiumService.upgradePremiumWithCredit, {
          stripeToken: params.stripeToken,
          idempotencyToken: params.idempotencyToken,
          plan: params.plan,
          lang: params.lang,
          isDiscounted: params.isDiscounted,
          currency: params.currency,
          planId: params.planId,
          needConfirm3DS: true,
          customer: response.customer,
          subscription: response.subscription,
          coupon: response.coupon,
        });
        if (isError(resData)) throw resData;
      } else {
        throw res;
      }
    }
    yield call(updateProfile, params.lang);
    yield put(hideLoadingFullScreen());
    I18nRouter.push('/register-premium-success', '/register-premium/success');
  } catch (error) {
    yield put(hideLoadingFullScreen());
    yield put(
      showError({
        error,
        title: i18n.t('registering_premium_failed'),
      }),
    );
  }
}

function* updateCountdown(action) {
  const { remainingDurationInMilliseconds } = action;
  // console.log('updateCountdownnnnn', remainingDurationInMilliseconds);
  Cookies.set(
    'countdown.remainingDurationInMilliseconds',
    remainingDurationInMilliseconds,
  );
  yield put({
    type: UPDATE_COUNTDOWN_SUCCESS,
    remainingDurationInMilliseconds,
    isActive: remainingDurationInMilliseconds > 0,
  });
}

function* createPaymentIntentOfSinglePremium(params) {
  try {
    yield put(selectedSinglePremium(params.plan));
    const paymentIntent = yield call(
      premiumService.createPaymentIntentOfSinglePremium,
      {
        plan: params.plan.type,
        id: params.plan.id,
      },
    );
    yield put({
      type: CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_SUCCESS,
      paymentIntent,
    });
  } catch (error) {
    yield put({
      type: CREATE_PAYMENT_INTENT_SINGLE_PREMIUM_FAILED,
    });
    yield put(closeModal());
    yield put(showError({ error }));
  }
}

/**
 *
 * Root saga manages watcher lifecycle
 */
export default function* pricePremiumSaga() {
  yield takeLatest(LOAD_SINGLE_PRICE_PREMIUM, loadSinglePricePremium);
  yield takeLatest(LOAD_PRICE_PREMIUM, loadPricePremium);
  yield takeLatest(CREATE_BILLING_AGREEMENT, createBillingAgreement);
  yield takeLatest(UPGRADE_PREMIUM_WITH_CREDIT, upgradePremiumWithCredit);
  yield takeLatest(INIT_COUNTDOWN, initCountdown);
  yield takeLatest(UPDATE_COUNTDOWN, updateCountdown);
  yield takeLatest(
    CREATE_PAYMENT_INTENT_SINGLE_PREMIUM,
    createPaymentIntentOfSinglePremium,
  );
}
