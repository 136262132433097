import { takeLatest, call, put, select } from 'redux-saga/effects';
import { i18n } from 'next-i18next';
import {
  UPGRADE_EL_PLAN_WITH_CREDIT,
  LOAD_PRICE_EL_PLANS,
  LOAD_PRICE_EL_PLANS_SUCCESS,
  LOAD_PRICE_EL_PLANS_ERROR,
  SAVE_LICENSE,
  BUY_SINGLE_LICENSE,
} from './constants';
import {
  showLoadingFullScreen,
  hideLoadingFullScreen,
  closeModal,
  hideLoadingExtraLicense,
} from './actions';
import { makeSelectPlanOfSingleLicense } from './selectors';
import { updateProfile } from '../AuthProvider/saga';
import { updatePhoto } from '~/containers/PhotoPage/actions';
import * as extraLicenseService from '~/services/extraLicenseService';
import { isError } from '~/utils/errorHandler';
import { showError, showInfor } from '~/containers/CustomErrorMessage/actions';

function* loadExtraLicensePlan() {
  try {
    const extraLicensePlanResponse = yield call(
      extraLicenseService.getExtraLicensePlan,
      {},
    );
    if (isError(extraLicensePlanResponse)) throw extraLicensePlanResponse;
    yield put({
      type: LOAD_PRICE_EL_PLANS_SUCCESS,
      el_plans: extraLicensePlanResponse,
    });
  } catch (error) {
    yield put({
      type: LOAD_PRICE_EL_PLANS_ERROR,
      error: (error && error.response && error.response.data) || {},
    });
  }
}

function* saveExtraLicense({ params }) {
  try {
    const response = yield call(extraLicenseService.saveExtraLicense, {
      service_type: params.serviceType,
      data_id: params.id,
      fullname: params.fullName,
      reason: params.reason,
      remark: params.remark,
    });
    if (isError(response)) throw response;
    yield put(
      updatePhoto({
        current_user_extra_license: response.extraLicense,
      }),
    );
    yield put(
      showInfor({
        description: !response.renewal
          ? i18n.t('add_extra_license_result', {
              numOfRemainedEL: response.remainingLicense,
            })
          : i18n.t('add_extra_license_result_renewal', {
              numOfRemainedEL: response.remainingLicense,
            }),
        title: i18n.t('add_extra_license_result_title'),
        typeInfor: 'add_extra_license',
      }),
    );
    yield call(updateProfile, params.lang);
  } catch (error) {
    console.log(error);
    yield put(
      showError({
        error,
        title: i18n.t('save_add_extra_license_failed'),
      }),
    );
  }
}

function* upgradeExtraLiencePlan(params) {
  try {
    yield put(showLoadingFullScreen());
    yield put(closeModal());

    const response = yield call(
      extraLicenseService.upgradeExtraLicenseWithCredit,
      {
        currency: params.currency,
        packId: params.packId,
        lang: params.lang,
      },
    );
    if (isError(response)) throw response;
    console.log('response', response);

    if (response.isNeed3DS && params.stripe) {
      const res = yield params.stripe.confirmCardPayment(
        response.charge.client_secret,
      );
      console.log('isNeed3DSisNeed3DS', res);
      if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
        const resData = yield call(
          extraLicenseService.upgradeExtraLicenseWithCredit,
          {
            currency: params.currency,
            packId: params.packID,
            lang: params.lang,
            needConfirm3DS: true,
            charge: response.charge,
            customerId: response.customerId,
          },
        );
        if (isError(resData)) throw resData;
      } else {
        throw res;
      }
    }

    yield call(updateProfile, params.lang);
    yield put(hideLoadingFullScreen());
    yield put(hideLoadingExtraLicense());
    yield put(
      showInfor({
        description: i18n.t('register_extra_license_pack_sucess_content'),
        title: i18n.t('register_extra_license_success_title'),
      }),
    );
  } catch (error) {
    yield put(hideLoadingFullScreen());
    yield put(
      showError({
        error,
        title: i18n.t('register_extra_license_pack_failed'),
      }),
    );
  }
}

function* buySingleLicense(action) {
  try {
    yield put(showLoadingFullScreen());
    const planOfSingleLicense = yield select(makeSelectPlanOfSingleLicense());
    console.log('planOfSingleLicenseeeee', planOfSingleLicense);

    const paymentResponse = yield call(
      extraLicenseService.upgradeExtraLicenseWithCredit,
      {
        currency: action.payload.currency,
        packId: action.payload.packID,
        lang: action.payload.lang,
      },
    );
    console.log(paymentResponse);
    if (paymentResponse.isNeed3DS && action.payload.stripe) {
      const res = yield action.payload.stripe.confirmCardPayment(
        paymentResponse.charge.client_secret,
      );
      console.log('isNeed3DSisNeed3DS', res);
      if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
        const resData = yield call(
          extraLicenseService.upgradeExtraLicenseWithCredit,
          {
            currency: action.payload.currency,
            packId: action.payload.packID,
            lang: action.payload.lang,
            needConfirm3DS: true,
            charge: paymentResponse.charge,
            customerId: paymentResponse.customerId,
          },
        );
        if (isError(resData)) throw resData;
      } else {
        throw res;
      }
    }
    // if (isError(paymentResponse)) throw paymentResponse;

    const licenseResponse = yield call(extraLicenseService.saveExtraLicense, {
      service_type: planOfSingleLicense.service_type,
      data_id: action.payload.dataID,
      reason: action.payload.reason,
      destinationUrl: action.payload.destinationUrl,
      fullname: action.payload.fullname,
    });
    if (isError(licenseResponse)) throw licenseResponse;
    yield put(
      updatePhoto({
        current_user_extra_license: licenseResponse.extraLicense,
      }),
    );

    yield put(
      showInfor({
        description: i18n.t('register_extra_license_pack_sucess_content'),
        title: i18n.t('register_extra_license_success_title'),
      }),
    );
    yield put(hideLoadingExtraLicense());
    yield put(hideLoadingFullScreen());
  } catch (error) {
    yield put(hideLoadingFullScreen());
    yield put(
      showError({
        error,
        title: i18n.t('register_extra_license_pack_failed'),
      }),
    );
  }
}

export default function* extraLicenseSaga() {
  yield takeLatest(LOAD_PRICE_EL_PLANS, loadExtraLicensePlan);
  yield takeLatest(UPGRADE_EL_PLAN_WITH_CREDIT, upgradeExtraLiencePlan);
  yield takeLatest(SAVE_LICENSE, saveExtraLicense);
  yield takeLatest(BUY_SINGLE_LICENSE, buySingleLicense);
}
