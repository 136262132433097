export const OPEN_MODAL = 'containers/ExtraLicensePaymentModal/OPEN_MODAL';
export const CLOSE_MODAL = 'containers/ExtraLicensePaymentModal/CLOSE_MODAL';
export const MODAL_SUBMIT = 'containers/ExtraLicensePaymentModal/MODAL_SUBMIT';

export const LOAD_PRICE_EL_PLANS =
  'containers/ExtraLicensePaymentModal/LOAD_PRICE_EL_PLANS';
export const LOAD_PRICE_EL_PLANS_SUCCESS =
  'containers/ExtraLicensePaymentModal/LOAD_PRICE_EL_PLANS_SUCCESS';
export const LOAD_PRICE_EL_PLANS_ERROR =
  'containers/ExtraLicensePaymentModal/LOAD_PRICE_EL_PLANS_ERROR';

export const SELECTED_PRICE_EL_PLAN =
  'containers/ExtraLicensePaymentModal/SELECTED_PRICE_EL_PLAN';
export const CREATE_EL_BILLING_AGREEMENT =
  'containers/ExtraLicensePaymentModal/CREATE_EL_BILLING_AGREEMENT';
export const UPGRADE_EL_PLAN_WITH_CREDIT =
  'containers/ExtraLicensePaymentModal/UPGRADE_EL_PLAN_WITH_CREDIT';

export const SHOW_LOADING_FULL_SCREEN =
  'containers/ExtraLicensePaymentModal/SHOW_LOADING_FULL_SCREEN';
export const HIDE_LOADING_FULL_SCREEN =
  'containers/ExtraLicensePaymentModal/HIDE_LOADING_FULL_SCREEN';

export const REDIRECT_LOGIN =
  'containers/ExtraLicensePaymentModal/REDIRECT_LOGIN';

export const SAVE_LICENSE = 'containers/ExtraLicensePaymentModal/SAVE_LICENSE';
export const BUY_SINGLE_LICENSE =
  'containers/ExtraLicensePaymentModal/BUY_SINGLE_LICENSE';
export const HIDE_LOADING_EXTRA_LICENSE =
  'containers/ExtraLicensePaymentModal/HIDE_LOADING_EXTRA_LICENSE';
